/* add this for justify layout */
.queryBuilder .ruleGroup-addGroup + button.ruleGroup-cloneGroup, .queryBuilder .ruleGroup-addGroup + button.ruleGroup-lock, .queryBuilder .ruleGroup-addGroup + button.ruleGroup-remove, .queryBuilder .rule-operators + button.rule-cloneRule, .queryBuilder .rule-operators + button.rule-lock, .queryBuilder .rule-operators + button.rule-remove, .queryBuilder .rule-value + button.rule-cloneRule, .queryBuilder .rule-value + button.rule-lock, .queryBuilder .rule-value + button.rule-remove, .queryBuilder .control + button.rule-cloneRule, .queryBuilder .control + button.rule-lock, .queryBuilder .control + button.rule-remove, .queryBuilder .chakra-select__wrapper + button.rule-cloneRule, .queryBuilder .chakra-select__wrapper + button.rule-lock, .queryBuilder .chakra-select__wrapper + button.rule-remove {
  margin-left: auto;
}
.rule-value{
  width: 50%!important;
}

.ruleGroup .ruleGroup-header,.rule-fields,.rule-operators {
  width: fit-content!important;
}

/* .queryBuilder-invalid {
  border-color: rebeccapurple;
  border-width: 4px;
} */
.validateQuery
.queryBuilder
.rule.queryBuilder-invalid
.rule-value::placeholder {
color: #47246b;
}

.validateQuery .queryBuilder .rule.queryBuilder-invalid .rule-value {
  background-color: #66339966;
}

.checkbox-wrapper{
  padding-right: 0px !important;
}

.validateQuery .queryBuilder-invalid.ruleGroup {
  border-color: red;
  border-width: 2px;
}

.MuiDayPicker-header .MuiDayPicker-weekDayLabel{
  color: var(--mui-palette-text-primary);
}

.MuiPickersFadeTransitionGroup-root{
  font-weight: 550;
}

.MuiPickersDay-root {
  background-color: var(--mui-palette-background-paper) !important;
  color: var(--mui-palette-text-primary) !important;
  transition: all 0.2s ease-in-out;
}

.MuiPickersDay-root:hover {
  background-color: #a4aab3 !important;
  border: 1px solid rgba(0, 0, 0, 0.6);
}
.MuiPickersDay-root.Mui-selected{
  background-color: var(--mui-palette-action-selected)!important;
  border: 1px solid var(--mui-palette-text-primary) !important ;
}

.MuiClock-clock{
  background-color: rgba(0, 0, 0, 0.07) !important;
}

.MuiPickersDay-today{
  border: 1px solid var(--mui-palette-text-primary) !important ;
}

.MuiClockPointer-root{
  background-color: rgb(25, 118, 210) !important;
}

.MuiClockNumber-root.Mui-selected{
  background-color: rgb(25, 118, 210) !important;

}

.MuiClockNumber-root{
  color: black !important;
}

.rule-value, .rule-fields .MuiInputBase-colorPrimary, .rule-operators .MuiInputBase-colorPrimary, .ruleGroup-combinators .MuiSelect-select, .MuiInputBase-colorPrimary{
  color: var(--mui-palette-text-primary) !important;
}