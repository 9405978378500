.custom-header{
    flex-direction: column;
}

.custom-header .MuiCheckbox-root{
    padding-left: 3px;
    padding-bottom: 4px;

}


.custom-group-header .MuiCheckbox-root{
    padding-left: 3px;
    padding-bottom: 4px;
}

.ag-header-group-cell{
 justify-content: center;
}