.shipping-report-col-date {
    width: 175px;
}

.shipping-report-col-orderNumber {
    width: 130px;
}

.shipping-report-delivery-method {
    width: 140px;
}

.shipping-report-notes {
    white-space: pre-wrap;
    word-wrap: break-word;
    max-width: 400px;
}

.shipping-report-col-flex {
    flex: 1;
}

.shipping-report-col-inhand-warn{
    color:darkorange !important
}

.shipping-report-col-inhand-danger{
    color: red !important;
}