.ag-cell, .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    line-height: 25px;  
    /* display: grid;  
    align-items: center; */
}
.ag-header-cell, .ag-header-group-cell, .ag-cell {
    padding-left: 4px;
    padding-right: 6px;
}
/* config for copy text */
.ag-cell {
    user-select: text !important;
    /* Enables text selection */
    cursor: text;
    /* Changes the cursor to text selection style */
}