.weekend {
    /* background-color: rgba(53, 45, 158, 0.616); */
    background-color: rgba(8, 72, 167, 0.637);
}

/* .month {
    background-color: rgb(30, 87, 210);
} */

#schedule .current-month {
    background-color: chocolate;
}

#schedule .current-month .ag-header-cell-label{
    display: grid;
    text-align: left;
}  

#schedule .month .ag-header-cell-label{
    display: grid;
    text-align: left;
} 

.italic-cell {
    font-style: italic;
}

.bold-cell {
    font-weight: bold;
}

.attractive-cell {
    background-color: yellow !important;
}

.past-cell {
    color: #a2b2bd;
    font-style: italic;
}

.leave-cell {
    color: red;
}

.current-week {
    background-color: #ffd9eb83;
}
.today {
    background-color: #267d1d94;
}
#schedule .ag-header-group-cell-label{
   flex: unset;
}
#schedule .ag-header-cell-label{
    display: grid;
    text-align: center;
}
.center-cell{
    text-align: center;
}
.teamlead {
    color: orange;
}